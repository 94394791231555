.pick-em-view {
    overflow: hidden;
    flex-grow: 1;
}

.pick-em-week-match-ups {
    overflow: hidden;
}

.pick-em-match-ups-list {
    overflow-y: auto;
    border-top: var(--jc-app-separator-border);
    border-bottom: var(--jc-app-separator-border);
    padding-right: var(--jc-default-gap);
}

.pick-em-match-ups-list .pick-em-match-up-tile {
    flex-direction: column;
    align-items: start;
    border-bottom: var(--jc-app-separator-border);
    padding-bottom: var(--jc-default-gap);
}

.pick-em-match-ups-list .pick-em-match-up-tile .pick-em-match-up-tile-teams {
    width: 100%;
}

.pick-em-match-ups-list .pick-em-match-up-tile:first-child {
    margin-top: var(--jc-default-gap);
}

.pick-em-match-ups-list .pick-em-match-up-tile:last-child {
    margin-bottom: var(--jc-default-gap);
    border-bottom: none;
}

.pick-em-match-up-team-row.jc-button {
    border: none;
    border-radius: 0;
    align-items: center;
    flex-grow: 1;
    justify-content: space-between;
}