@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,300;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

@import url('./app/games/nflBets/NFLBets.css');
@import url('./app/games/pick-em/PickEm.css');
@import url('./app/chat/Chat.css');
@import url('./app/projects/Projects.css');
@import url('./JcSDK/JcSDK.css');
@import url('./JcSDK/button/JcButton.css');
@import url('./JcSDK/dialog/JcDialog.css');
@import url('./JcSDK/JcBox.css');
@import url('./JcSDK/JcList.css');
@import url('./app/home/HomeScreen.css');
@import url('./app/JcApp.css');
@import url("allotment/dist/style.css");

body {
  width: 100%;
  margin: 0;
  font-family: "Roboto Mono";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--jc-app-background-color);
  color: var(--jc-app-text-color);
}

#root {
  height: 100%;
  width: 100%;
  position: fixed;
  overflow-y: auto;
}