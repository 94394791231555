:root {
  --jc-button-border-radius: 5px;
  --jc-button-bar-height: calc(2 * var(--jc-default-gap) + var(--jc-icon-small-size));
}

/* misc */

.jc-selected.jc-button {
  background-color: var(--jc-button-selected-color);
}

.jc-clickable:hover {
  background-color: var(--jc-button-hover-color);
  cursor: pointer;
}

.jc-button.jc-disabled {
  background-color: var(--jc-button-disabled-color);
  cursor: not-allowed;
}

/* jc-button */

.jc-button {
  border: var(--jc-app-separator-border);
  border-radius: var(--jc-button-border-radius);
  padding: var(--jc-default-small-gap) var(--jc-default-gap);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: "Roboto Mono";
  background-color: transparent;
  color: var(--jc-app-text-color);
}

.jc-button.jc-no-border-button {
  border: none;
  border-radius: unset;
}

.jc-icon-button.jc-button {
  border-radius: 100%;
  padding: var(--jc-default-gap);
}

/* jc-button-bar */

.jc-button-bar {
  overflow-x: auto;
  /* FIXME jcallard: Why do I need this?*/
  min-height: var(--jc-button-bar-height);
  height: var(--jc-button-bar-height);
}

.jc-button-bar .jc-selected:not(:hover).jc-button {
  background-color: transparent;
}

.jc-button-bar .jc-selected.jc-button {
  font-weight: bold;
  font-style: italic;
  border-bottom: 4px solid var(--jc-app-text-color);
  padding-bottom: calc(var(--jc-default-small-gap) - 4px);
}

.jc-button-bar>.jc-button-bar-button {
  flex-grow: 1;
  flex-basis: 0px;
}

/* jc-clickable-text */

.jc-clickable-text.jc-button {
  background-color: transparent;
  padding: 0;
}

.jc-clickable-text.jc-button:hover {
  transform: scale(1.1);
  background-color: transparent;
  color: var(--jc-app-text-selected-color);
  transition: transform 0.25s;
}

.jc-clickable-text.jc-selected {
  background-color: transparent;
  color: var(--jc-app-text-selected-color);
}

/* jc-dropdown */

.jc-dropdown-popup-content .jc-dropdown-list {
  min-width: 120px;
  border: var(--jc-app-separator-border);
  margin: var(--jc-default-small-gap) 0;
  overflow-y: auto;
  max-height: calc(100% - 2 * var(--jc-default-small-gap));
}

.jc-dropdown-position-top-content {
  top: 0;
}

.jc-dropdown-position-bottom-popup-content {
  bottom: 0;
}