:root {
  --jc-app-page-padding: 20px;
  --jc-app-off-white: #d2c6f5;

  --jc-darkest-color: rgb(34, 31, 43);
  --jc-darkest-color-translucent: rgb(34, 31, 43, 0.7);
  --jc-second-darkest-color: #2a2536;
  --jc-third-darkest-color: #363045;
  --jc-fourth-darkest-color: #665b84;
  --jc-fifth-darkest-color: #8072a6;

  --jc-app-dark-disabled-color: #47464d;
  --jc-app-light-disabled-color: #b5acce;

  --jc-app-background-color: var(--jc-darkest-color);
  --jc-app-background-translucent: var(--jc-darkest-color-translucent);
  --jc-app-secondary-color: var(--jc-second-darkest-color);
  --jc-app-tertiary-color: var(--jc-third-darkest-color);
  --jc-app-text-color: var(--jc-fifth-darkest-color);
  --jc-app-placeholder-color: var(--jc-fourth-darkest-color);
  --jc-app-text-selected-color: var(--jc-app-off-white);

  --jc-button-hover-color: #3b354d;
  --jc-button-selected-color: var(--jc-app-tertiary-color);
  --jc-button-disabled-color: var(--jc-app-dark-disabled-color);

  --jc-app-separator-border: 1px solid var(--jc-app-text-color);
  --jc-app-border-radius: 10px;
  --jc-app-title-bar-height: 45px;
}

.jc-app-white-background {
  background-color: var(--jc-app-off-white);
  color: var(--jc-darkest-color);
  --jc-app-text-color: var(--jc-darkest-color);
  --jc-app-text-selected-color: var(--jc-fifth-darkest-color);
  --jc-app-secondary-color: var(--jc-fifth-darkest-color);
  --jc-button-hover-color: var(--jc-third-darkest-color);
  --jc-app-placeholder-color: var(--jc-second-darkest-color);
  --jc-button-selected-color: var(--jc-fourth-darkest-color);
  --jc-button-disabled-color: var(--jc-app-light-disabled-color);
}

.jc-app {
  height: 100%;
  overflow-y: scroll;
}

.jc-page-with-title-bar {
  padding-top: var(--jc-app-title-bar-height);
}

/* jc-app-title-bar */

.jc-app-title-bar {
  max-width: var(--jc-max-page-width);
  z-index: 1;
  width: calc(100% - 2 * var(--jc-app-page-padding));
  background-color: var(--jc-app-background-translucent);
  border-bottom: var(--jc-app-separator-border);
  justify-content: space-between;
  font-style: italic;
  position: fixed;
  height: var(--jc-app-title-bar-height);
  top: 0;
}

.jc-app-title-bar .jc-clickable-text {
  font-style: italic;
  font-size: medium;
}

.jc-app-title-text.jc-clickable-text {
  font-style: italic;
  font-size: larger;
  font-weight: 900;
}

.jc-app-title-bar .jc-app-title-locations {
  gap: var(--jc-default-large-gap);
}

/* jc-app-footer */

.jc-app-footer {
  border-top: var(--jc-app-separator-border);
  padding: 30px 0;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;

}

.jc-app-footer .jc-app-social>a {
  font-size: larger;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid var(--jc-app-text-color);
  color: inherit;
  opacity: 0.7;
}

.jc-app-footer .jc-app-social>a:hover {
  opacity: 1;
}

.jc-app-footer .jc-app-copyright {
  text-align: center;
  font-size: medium;
}

.jc-app-footer .jc-app-social-icon:hover {
  transform: scale(1.2);
  transition: transform 0.25s;
}

/* jc-app-page-not-found */

.jc-app-page-not-found {
  min-height: calc(100vh - var(--jc-app-title-bar-height) - var(--jc-app-page-padding));
}

.jc-app-page-not-found .jc-app-not-found-content {
  padding: 20px;
  justify-content: center;
  flex-grow: 1;
}

.jc-app-page-not-found .jc-app-not-found-content .jc-app-not-found-title {
  font-size: x-large;
}

.jc-app-page-not-found .jc-app-not-found-content .jc-app-not-found-text {
  font-size: larger;
}

/* jc-app-navigation-drawer */

.jc-app-navigation-drawer {
  height: 100%;
  width: 60vw;
  border-left: var(--jc-app-separator-border);
}

.jc-app-navigation-drawer .jc-app-drawer-title {
  width: 100%;
  align-self: start;
  justify-content: start;
  padding: var(--jc-default-gap);
}

.jc-app-navigation-drawer .jc-list-item {
  font-style: italic;
  font-size: medium;
}

.jc-app-title-hamburger.jc-button {
  display: none;
}

@media only screen and (max-width: 650px) {

  :root {
    --jc-app-border-radius: unset;
  }

  /* jc-app-title-bar */

  .jc-app-title-bar {
    width: 100%;
    left: 0;
  }

  .jc-app-title-bar .jc-app-title-hamburger {
    display: inline;
  }

  .jc-app-title-bar .jc-app-title-locations {
    display: none;
  }

  .jc-app-title-bar .jc-app-title-top-left {
    padding-left: var(--jc-default-gap);
  }

  /* nfl-bets-page */

  .nfl-bets-page {
    --jc-app-page-padding: 0px;
  }

  .nfl-bets-page .nfl-bets-view .nfl-bets-graph {
    border-radius: 0;
  }

  .nfl-bets-page .nfl-bets-users-view {
    font-size: 12px;
  }

}