.jc-box {
  display: flex;
}

/* Boxes */
.jc-box.jc-column {
  flex-direction: column;
}

.jc-box.jc-row {
  flex-direction: row;
}

.jc-box.jc-spacing {
  gap: var(--jc-default-gap);
}

.jc-box.jc-align-center {
  align-items: center;
}

.jc-box.jc-justify-center {
  justify-content: center;
}

.jc-box.jc-flex-grow {
  flex-grow: 1;
}