:root {
  --jc-list-border-radius: 10px;
  --jc-list-min-width: 120px;
}

/* jc-list */

.jc-list {
  border-radius: var(--jc-list-border-radius);
  padding: 0;
  margin: 0;
}

.jc-list.jc-list-no-border {
  border-radius: 0;
  padding: 0;
  margin: 0;
}

.jc-list .jc-list-item {
  justify-content: flex-start;
  width: 100%;
}

.jc-list .jc-list-item:first-child {
  border-top-left-radius: var(--jc-list-border-radius);
  border-top-right-radius: var(--jc-list-border-radius);
}

.jc-list.jc-list-no-border .jc-list-item:first-child {
  border-radius: 0;
}

.jc-list .jc-list-item:last-child {
  border-bottom-left-radius: var(--jc-list-border-radius);
  border-bottom-right-radius: var(--jc-list-border-radius);
}

.jc-list.jc-list-no-border .jc-list-item:last-child {
  border-radius: 0;
}