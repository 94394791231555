:root {
    --win-color: rgb(12, 117, 64);
    --over-under-line-color: rgb(9, 122, 203);
    --loss-color: rgb(167, 80, 71);
    --tie-color: rgb(142, 142, 72);
}

.nfl-bets-page.jc-page.jc-page-with-title-bar {
    flex-grow: 1;
    overflow: hidden;
}

.nfl-bets-page .jc-app-title-bar {
    border-bottom: none;
}

.nfl-bets-agreements {
    font-size: x-small;
}

.nfl-bets-log-in {
    padding: var(--jc-default-gap);
    border: var(--jc-app-separator-border);
    border-radius: 10px;
    max-width: 275px;
    background-color: var(--jc-app-secondary-color);
}

.nfl-bets-sign-in-info {
    align-items: end;
    padding: var(--jc-default-gap);
    max-width: 275px;
}

.nfl-bets-page .nfl-bets-view {
    overflow: hidden;
    flex-grow: 1;
}

.nfl-bets-page .nfl-bets-view .nfl-bets-users-view {
    overflow: hidden;
}

.nfl-bets-page .nfl-bets-view .nfl-bets-graph {
    overflow-x: scroll;
    gap: 1px;
    flex-grow: 1;
    font-size: 12px;
}

.nfl-bets-team-label {
    justify-content: end;
    padding: var(--jc-default-gap);
}

.nfl-bets-graph .nfl-bet-box-line.bet-over-under-line {
    background-color: var(--over-under-line-color);
}

.nfl-bets-graph .nfl-bet-box-line {
    height: 3px;
}

.nfl-bets-graph .nfl-bets-box-wrapper {
    padding: 2px;
    display: flex;
    flex-grow: 1;
}

.nfl-bets-graph .nfl-bets-box {
    flex-grow: 1;
    background-color: var(--jc-app-tertiary-color);
}

.nfl-win {
    background-color: var(--win-color) !important;
    color: var(--jc-app-off-white) !important;
}

.nfl-loss {
    background-color: var(--loss-color) !important;
    color: var(--jc-app-off-white) !important;
}

.nfl-tie {
    background-color: var(--tie-color) !important;
}

.nfl-bets-graph .nfl-bets-box.nfl-bets-box-selected {
    background-color: lightblue;
}

.nfl-bets-graph .nfl-bets-box-container {
    position: relative;
    display: flex;
    width: 100%;
    flex-grow: 1;
}

/* Bets Brackets */

.nfl-bets-graph .nfl-bets-bracket {
    position: absolute;
    height: 100%;
    width: 100%;
}

.nfl-bets-graph .nfl-bets-bracket .box-decoration {
    position: absolute;
}

.nfl-bets-graph .nfl-bets-bracket .nfl-bet-box-sides-right {
    right: 0;
}

.nfl-bets-graph .nfl-bets-bracket .nfl-bet-box-sides-left {
    left: 0;
}

.nfl-bets-graph .nfl-bets-bracket .nfl-bet-box-sides {
    width: 4px;
    height: 100%;
}

.nfl-bets-graph .nfl-bets-bracket .nfl-bet-box-sides.nfl-bet-box-sides-under {
    background: var(--over-under-line-color);
    background: linear-gradient(0deg, transparent 0%, var(--over-under-line-color) 100%);
}

.nfl-bets-graph .nfl-bets-bracket .nfl-bet-box-sides.nfl-bet-box-sides-over {
    background: var(--over-under-line-color);
    background: linear-gradient(0deg, var(--over-under-line-color) 0%, transparent 100%);
}

.nfl-bets-graph .nfl-bets-bracket .nfl-bet-box-line {
    height: 4px;
    width: 100%;
    background-color: var(--over-under-line-color);
}

.nfl-bets-graph .nfl-bets-bracket .nfl-bet-box-line {
    bottom: 0;
}

.nfl-bets-graph .nfl-bets-bracket .nfl-bet-box-line-under {
    top: 0;
}


.nfl-bets-line-label {
    width: 100%;
    padding: var(--jc-default-gap) 2px;
    background-color: var(--jc-app-secondary-color)
}

.nfl-users-chart-view {
    overflow: hidden;
}

.nfl-users-stats-view {
    overflow: hidden;
}

.nfl-info-message {
    padding: var(--jc-default-gap);
    max-width: 400px;
    ;
}

.nfl-bets-user-info {
    padding: var(--jc-default-gap);
}

.nfl-bets-submit-bar {
    flex-grow: 1;
}

.nfl-bets-user-label-image {
    padding: var(--jc-default-gap);
    background-color: var(--jc-app-tertiary-color);
}

.nfl-users-stats-view .jc-box {
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    padding: var(--jc-default-gap);
}

.nfl-bets-user-column-header {
    position: sticky;
    background-color: var(--jc-app-background-translucent);
    top: 0;
    z-index: 1;
    border-right: var(--jc-app-separator-border);
    padding: var(--jc-default-small-gap);
    overflow: hidden;
    height: 100px;
}

.nfl-bets-user-column-header.last-child {
    border-right: none;
}

.nfl-bets-user-row-header {
    width: calc(2 * var(--jc-default-gap) + var(--image-size-medium));
    position: sticky;
    left: 0;
    border-bottom: var(--jc-app-separator-border);
}

.nfl-bets-user-row-header.last-child {
    border-bottom: none;
}

.nfl-bets-user-row-header.nfl-bets-user-column-header {
    z-index: 2;
    border: none;
}

.nfl-bets-user-row-box-wrapper {
    padding: var(--jc-default-small-gap);
}

.nfl-bets-user-row-box-wrapper .nfl-bets-user-row-box {
    width: 100%;
}

.nfl-bets-division-selection {
    padding: var(--jc-default-gap);
}