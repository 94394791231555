:root {
  --jc-default-gap: 10px;
  --jc-default-large-gap: 20px;
  --jc-default-small-gap: 5px;
  --jc-default-tiny-gap: 3px;
  --min-page-height: calc(100vh - 2 * var(--jc-app-page-padding));
  --jc-image-size-medium: 48px;
  --jc-image-size-small: 32px;
  --jc-max-page-width: 1000px;
  --jc-separator-color: var(--jc-app-text-color);
  --jc-separator-thickness: 1px;
  --jc-icon-small-size: 24px;
  --jc-input-text-size: 16px;
}

/* jc-icon */
.jc-icon {
  display: flex;
  align-items: center;
}

.jc-icon.jc-icon-size-extra-small .jc-icon-img {
  height: 16px;
  width: 16px;
}

.jc-icon.jc-icon-size-small .jc-icon-img {
  height: var(--jc-icon-small-size);
  width: var(--jc-icon-small-size);
}

.jc-icon.jc-icon-size-medium .jc-icon-img {
  height: 32px;
  width: 32px;
}

.jc-icon.jc-icon-size-large .jc-icon-img {
  height: 64px;
  width: 64px;
}

/* jc-image */

.jc-image {
  width: auto;
  height: auto;
}

.jc-image.jc-image-circle {
  border-radius: 50%;
}

.jc-image.jc-image-size-extra-small {
  max-width: 16px;
  max-height: 16px;
}

.jc-image.jc-image-size-small {
  max-width: var(--jc-image-size-small);
  max-height: var(--jc-image-size-small);
}

.jc-image.jc-image-size-medium {
  max-width: var(--jc-image-size-medium);
  max-height: var(--jc-image-size-medium);
}

.jc-image.jc-image-size-large {
  max-width: 128px;
  max-height: 128px;
}

.jc-image.jc-image-size-extra-large {
  max-width: 256px;
  max-height: 256px;
}

.jc-image.jc-image-size-xx-large {
  max-width: 324px;
  max-height: 324px;
}

/* jc-label */
.jc-label {
  white-space: nowrap;
  font-weight: bold;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}

/* jc-page */

.jc-page {
  max-width: var(--jc-max-page-width);
  width: calc(100% - 2 * var(--jc-app-page-padding));
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: var(--jc-app-page-padding);
}

/* jc-full-page-loading-indicator */

.jc-full-page-loading-indicator {
  flex-grow: 1;
  justify-content: center;
  display: flex;
  align-items: center;
}

.jc-full-page-loading-indicator .jc-loading-indicator-content {
  align-items: center;
}

/* jc-field-group */

.jc-field-group {
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  gap: var(--jc-default-gap) var(--jc-default-large-gap);
  align-items: center;
}

.jc-input {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid var(--jc-app-text-color);
  color: var(--jc-app-text-color);
  background-color: var(--jc-app-secondary-color);
  font-size: var(--jc-input-text-size);
}

.jc-input::placeholder {
  color: var(--jc-app-placeholder-color);
  font-style: italic;
}

.jc-input:focus {
  outline-style: inset;
}

/* jc-grid */

.jc-grid {
  flex-grow: 1;
  display: grid;
  grid-template-rows: min-content;
  overflow: auto;
}

/*  jc-notice */

.jc-notice {
  padding: var(--jc-default-gap);
  border: var(--jc-app-separator-border);
  background-color: var(--jc-app-tertiary-color);
  font-size: smaller;
  border-radius: 5px;
}

/* jc-checkbox */

.jc-checkbox {
  align-items: baseline;
}

/* jc-fade-in */

.jc-fade-in {
  opacity: 0;
  transform: scale(1.0, 0.9);
  visibility: hidden;
  transition: opacity 2s ease-out, transform 1.5s ease-out;
  will-change: opacity, visibility;
}

.jc-fade-in.jc-fade-in-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

/* jc-gradient */

.jc-gradient {
  box-shadow: 0 15px 15px rgba(201, 201, 201, 0.16);
  background-image: linear-gradient(160deg, #7bbef5 10%, #c9e6ab);
  color: #000;
  border-radius: 12.5px;
  font-family: "Quicksand";
}

.jc-gradient.jc-clickable:hover {
  background-image: linear-gradient(160deg, #7bbef5 70%, #c9e6ab);
}

/* jc-separator */

.jc-separator {
  background-color: var(--jc-separator-color);
  border: none;
}

.jc-separator.jc-separator-vertical {
  height: var(--jc-separator-length);
  width: var(--jc-separator-thickness);
  margin: var(--jc-default-small-gap) var(--jc-default-gap);
}

.jc-separator.jc-separator-horizontal {
  width: var(--jc-separator-length);
  height: var(--jc-separator-thickness);
  margin: var(--jc-default-gap) var(--jc-default-small-gap);
}

/* jc-tabs */

.jc-tabs .jc-tab {
  overflow: hidden;
  flex-grow: 1;
}

.jc-tabs .jc-hidden-tab {
  display: none;
}