/* projects */

.project-group {
    padding-bottom: 20px;
}

.project-title {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid;
    line-height: 0.1em;
    margin: 40px 0px 40px;
}

.project-image.jc-image {
    width: 100%;
    height: 100%;
}

.projects-display {
    padding: var(--jc-default-gap) 0;
    justify-content: space-around;
    display: inline-flex;
    flex-wrap: wrap;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
}

.project-image-container {
    width: 300px;
    height: 400px;
    position: relative;
    width: 100%;
}

.project-image {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
}

.project-image-overlay {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.project-image-container:hover .project-image {
    opacity: 0.3;
}

.project-image-container:hover .project-image-overlay {
    opacity: 1;
}

.project-image-text {
    background-color: var(--jc-app-secondary-color);
    color: var(--jc-app-off-white);
    font-size: 16px;
    padding: 16px 32px;
}

.project-title span {
    background: #221f2b;
    padding: 0 10px;
}

.projects>div {
    display: inline-grid;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    grid-gap: 1rem;
    color: #000;
    width: 100%;
}

.experience .project {
    background-image: linear-gradient(160deg, #c9e6ab 10%, #eaad52);
}

.project {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: left;
}

/* project tags */

.project .tags {
    display: flex;
    flex-wrap: wrap;
}

.project .tag {
    background: rgba(255, 255, 255, 0.3);
    padding: 5px;
    border-radius: 5px;
    margin: 2px;
    border: 1px solid var(--jc-app-text-color);
    ;
}

.project .tag:hover {
    background: rgba(255, 255, 255, 0.5);
}

@media only screen and (max-width: 400px) {
    .project-image-container {
        width: 100%;
        height: unset;
    }
}