.chat-page.jc-page {
    height: calc(100% - 2 * var(--jc-app-page-padding));
    overflow: hidden;
}

.chat-message-list {
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: var(--jc-default-large-gap);
    padding-left: var(--jc-default-small-gap);
    gap: var(--jc-default-gap);
}

.chat-message-list > .chat-message-block:first-child {
    margin-top: var(--jc-default-gap);
}

/* Gotta skip the dummy div */
.chat-message-list > .chat-message-block:nth-last-child(2) {
    margin-bottom: var(--jc-default-gap);
}

.chat-board {
    overflow: hidden;
    height: 100%;
}

.chat-input-row {
    padding: var(--jc-default-gap);
    border-top: var(--jc-app-separator-border);
}

.chat-input-row .chat-input {
    flex-grow: 1;
}

.chat-board-header {
    padding: var(--jc-default-gap);
    border-bottom: var(--jc-app-separator-border);
}

.chat-groups-list.jc-list {
    height: 100%;
    margin-right: var(--jc-default-gap);
}

.chat-group-column {
    align-items: start;
}

.chat-circle {
    height: var(--jc-image-size-small);
    width: var(--jc-image-size-small);
    background-color: var(--jc-app-secondary-color);
    border-radius: calc(var(--jc-image-size-small) / 2);
    font-size: x-large;
}

.chat-chatter {
    border-radius: var(--jc-app-border-radius);
    border: var(--jc-app-separator-border);
}

.chat-chatter .chat-chatter-toolbar {
    justify-content: end;
}

.chat-chatter .chat-chatter-select-chat {
    font-size: larger;
    font-style: italic;
    height: 100%;
}

/* .chat-message-block */

.chat-message-block {
    align-items: center;
    justify-content: stretch;
}

.chat-message-block .chat-message-block-row {
    width: 100%;
    gap: var(--jc-default-tiny-gap);
    align-items: end;
}

.chat-message-block .chat-message-bubbles {
    gap: var(--jc-default-tiny-gap);
    flex-grow: 1;
}

.chat-message-block .chat-message-bubble {
    background-color: var(--jc-app-text-color);
    border-radius: 10px;
    color: var(--jc-app-background-color);
    padding: var(--jc-default-small-gap) var(--jc-default-gap);
    max-width: 70%;
}

.chat-message-block.chat-message-block-left .chat-message-bubbles {
    align-items: start;
}

.chat-message-block.chat-message-block-right .chat-message-bubbles {
    align-items: end;
}

.chat-message-block.chat-message-block-left .chat-message-block-row {
    flex-direction: row;
}

.chat-message-block.chat-message-block-left .chat-message-bubble:last-child {
    border-bottom-left-radius: 0;
}

.chat-message-block.chat-message-block-right .chat-message-block-row {
    flex-direction: row-reverse;
}

.chat-message-block.chat-message-block-right .chat-message-bubble:last-child {
    border-bottom-right-radius: 0;
}

.chat-message-block .chat-message-bubbles {
    align-items: start;
}

.chat-message-block .chat-profile-picture.jc-button {
    padding: 0;
    border: none;
    border-radius: 50%;
}

.chat-message-block .chat-message-time-label {
    font-size: small;
}