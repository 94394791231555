/* jc-dialog */

.jc-dialog {
  padding: var(--jc-default-gap);
  border-radius: 20px;
  margin: auto;
  font-family: "Quicksand";
}

.jc-dialog .jc-dialog-top-bar {
  justify-content: space-between;
  border-bottom: var(--jc-app-separator-border);
  padding-bottom: calc(var(--jc-default-gap) / 2);
  font-size: x-large;
}

.jc-dialog .jc-dialog-top-bar .jc-dialog-close-icon {
  margin-left: auto;
}

.jc-dialog .jc-dialog-bottom-bar {
  justify-content: end;
  border-top: var(--jc-app-separator-border);
  padding-top: calc(var(--jc-default-gap) / 2);
}

.jc-dialog .jc-dialog-bottom-bar .jc-dialog-close {
  background-color: white;
  color: var(--jc-app-background-color);
}

.jc-dialog .jc-dialog-bottom-bar .jc-dialog-ok {
  background-color: var(--jc-app-tertiary-color);
  color: var(--jc-app-off-white);
}

.jc-dialog .jc-dialog-bottom-bar .jc-dialog-ok:hover {
  background-color: var(--jc-app-text-color);
}