/************ HOMEPAGE ************/

.home-moji {
    margin-left: auto;
    opacity: .8;
}

.home-screen {
    height: calc(100vh - var(--jc-app-title-bar-height));
    text-align: left;
    font-weight: bold;
}

.home-contact-me-content {
    width: 300px;
    padding: var(--jc-default-gap);
}


.home-screen .home-name {
    font-size: 4.5em;
    margin: 0;
}

.home-screen .home-role {
    font-size: 2.8em;
    font-weight: normal;
    margin: 0;
}

.home-screen .arrow-down-icon {
    width: 70px;
    height: 70px;
}

.home-screen .arrow-down {
    cursor: pointer;
    margin: 0 auto;
}

.home-screen .contact-me {
    font-weight: bold;
    font-size: large;
    border: none;
    margin: 30px 0;
    width: 200px;
    height: 50px;
    align-self: center;
}

@media only screen and (max-width: 650px) {
    .home-screen .contact-me {
        margin: 30px auto;
    }

    .home-moji {
        z-index: -1;
        opacity: .2;
        position: absolute;
        top: 43%;
        right: 0;
        transform: translate(0, -50%);
    }

    .home-meet-me-row {
        font-size: smaller;
    }
}